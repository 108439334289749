import React from "react"
import { FaUsers, FaStoreAlt } from 'react-icons/fa'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card/card"

const IndexPage = () => (
    <Layout>
        <SEO title="Registration" />
        <div className={"card-list"}>
            <Card title={"Visiteur"} link={"visitor"}/>
            <Card title={"Exposant"}/>
        </div>
    </Layout>
)

export default IndexPage
